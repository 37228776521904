import React from "react";

export default ({ text, isLoading, isEnabled, onClick, isSecondary }) => {
  return (
    <button
      className={`button ${!isSecondary ? "button--primary" : "button--secondary"}`}
      disabled={!isEnabled}
      onClick={onClick}
    >
      {Boolean(isLoading) && <i className="fas fa-spinner fa-spin"></i>}
      {text}
    </button>
  );
};
