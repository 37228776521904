import { Link } from "gatsby";
import React from "react";

export default ({ navigationColumn, isLeft }) => {
  if (Boolean(!navigationColumn || !navigationColumn.childItems)) {
    return <div />;
  }

  const lastSubItem = navigationColumn.childItems.slice(-1).pop();

  if (!lastSubItem) {
    return <></>;
  }
  return (
    <Link className="a--underline" to={`/${lastSubItem.content.general.path}`}>
      {lastSubItem.content.general.title}
    </Link>
  );
};
