import React from "react";

class QsmDuration extends React.Component {
  constructor(props) {
    super(props);

    this.handleDurationClick = this.handleDurationClick.bind(this);
  }

  // Action

  handleDurationClick(e) {
    e.preventDefault();

    const { duration, onDurationClick } = this.props;

    if (onDurationClick) {
      onDurationClick(duration);
    }
  }

  // Utils

  getDurationTitle(duration) {
    var minText = duration.minDuration;
    var separator = duration.maxDuration > 0 ? "-" : "of";
    var maxText = duration.maxDuration > 0 ? duration.maxDuration : "meer";

    return `${minText} ${separator} ${maxText} dagen`;
  }

  // Lifecycle

  render() {
    const { duration, selected } = this.props;

    const baseClassName = "panel__option";
    const selectedClassName = "panel__option--selected";
    const className = `${baseClassName}${selected ? " " + selectedClassName : ""}`;

    return (
      <div className={className} onClick={this.handleDurationClick}>
        <p>{this.getDurationTitle(duration)}</p>
      </div>
    );
  }
}

export default QsmDuration;
