import React from "react";

class QsmSailingArea extends React.Component {
  constructor(props) {
    super(props);

    this.handleSailingAreaClick = this.handleSailingAreaClick.bind(this);
  }

  // Actions

  handleSailingAreaClick(e) {
    e.preventDefault();

    const { sailingArea, onSailingAreaClick } = this.props;

    if (onSailingAreaClick) {
      onSailingAreaClick(sailingArea);
    }
  }

  // Lifecycle

  render() {
    const { sailingArea, selected } = this.props;

    const baseClassName = "panel__option";
    const selectedClassName = "panel__option--selected";
    const className = `${baseClassName}${selected ? " " + selectedClassName : ""}`;

    return (
      <div className={className} onClick={this.handleSailingAreaClick}>
        <p>{sailingArea.name}</p>
      </div>
    );
  }
}

export default QsmSailingArea;
