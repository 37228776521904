import { add, endOfMonth, isAfter, isPast, isThisMonth } from "date-fns";

import React from "react";
import { format } from "../../utils/dateUtils";

class QsmCalendarMonth extends React.Component {
  dateFormat = "MMM";

  constructor(props) {
    super(props);

    this.handleDateClick = this.handleDateClick.bind(this);
  }

  // Actions

  handleDateClick(e) {
    e.preventDefault();

    const { month, onDateClick } = this.props;

    // Only pass when date isn't in the past or in the far future
    if (onDateClick && !this.isInPast(month) && !this.isInFarFuture(month)) {
      onDateClick(month);
    }
  }

  // Utils

  getMonthTitle(month) {
    if (!month) {
      return "";
    }

    return format(month, this.dateFormat);
  }

  isInPast(month) {
    return isPast(month) && !isThisMonth(month);
  }

  isInFarFuture(month) {
    const monthToCompare = endOfMonth(add(new Date(), { months: 23 }));
    return isAfter(month, monthToCompare);
  }

  // Lifecycle

  render() {
    const { month, selected, disabled } = this.props;

    const baseClassName = "calendar__option";
    const selectedClassName = "calendar__option--selected";
    const disabledClassName = "calendar__option--disabled";
    let className = `${baseClassName}${selected ? " " + selectedClassName : ""}`;
    className = `${className}${disabled ? " " + disabledClassName : ""}`;

    return (
      <div className={className} onClick={this.handleDateClick}>
        <p>{this.getMonthTitle(month)}</p>
      </div>
    );
  }
}

export default QsmCalendarMonth;
