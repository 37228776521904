import React from "react";
import QsmDuration from "./qsmDuration";
import { find } from "lodash";

class QsmDurations extends React.Component {
  // Utils

  isInSelectedDurations(duration) {
    const { selectedDurations } = this.props;

    if (find(selectedDurations, { id: duration.id })) {
      return true;
    }
    return false;
  }

  // Lifecycle

  render() {
    const { durations, onDurationClick } = this.props;

    return (
      <div className="panel__options">
        {durations.map((duration, index) => (
          <QsmDuration
            duration={duration}
            selected={this.isInSelectedDurations(duration)}
            onDurationClick={onDurationClick}
            key={index}
          />
        ))}
      </div>
    );
  }
}

export default QsmDurations;
