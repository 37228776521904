import { Link } from "gatsby";
import React from "react";
import { getYear } from "date-fns";

export default () => (
  <section className="legal">
    <Link to="/disclaimer" className="legal__item">
      Disclaimer
    </Link>
    <Link to="/" className="legal__item">
      Legal
    </Link>
    <Link to="/" className="legal__item">
      &copy; {getYear(new Date())}, Thalassa Cruises
    </Link>
  </section>
);
