import { useEffect, useState } from "react";

import { Link } from "gatsby";
import React from "react";
import { environment } from "../environments/environment";

export default ({ hasHeader }) => {
  const [client, setClient] = useState(false);

  // Workaround to use the right className
  useEffect(() => {
    setClient(true);
  }, []);

  // PathParts
  const pathParts = getPathParts();

  // Fast escape
  if (!pathParts?.length) {
    return <></>;
  }

  const key = client ? "client" : "server";

  return (
    <div className={`header__breadcrumbs ${hasHeader ? "header__breadcrumbs--has-header" : ""}`} key={key}>
      <span className="header__breadcrumbs__title">Je bent hier:</span>
      {pathParts.map((pathPart, index) => (
        <Link to={pathPart.path} key={index} className="header__breadcrumbs__item">
          {pathPart.name}
        </Link>
      ))}
    </div>
  );
};

const getPathParts = () => {
  // Return if no path
  if (typeof window === "undefined" || !window?.location?.pathname) {
    return [];
  }

  const path = window.location.pathname.substr(1);

  // Return if no path
  if (!path) {
    return [];
  }

  let pathParts = path.split("/");

  // Filter out empty path parts
  pathParts = pathParts.filter(pathPart => pathPart);

  let parsedPathParts = pathParts.map(pathPart => {
    // Remove dashes
    let parsedPathPart = pathPart.split("-").join(" ");

    // Capitalize first letter
    parsedPathPart = `${parsedPathPart.charAt(0).toUpperCase()}${parsedPathPart.slice(1)}`;

    return {
      name: parsedPathPart,
      path: "/" + path.substr(0, path.indexOf(pathPart) + pathPart.length),
    };
  });

  // Return if no items
  if (!parsedPathParts?.length) {
    return [];
  }

  // Add home item
  const homeItem = {
    name: environment.builds[0].name,
    path: "/",
  };
  parsedPathParts.splice(0, 0, homeItem);

  return parsedPathParts;
};
