import { format as dateFnsFormat, getYear, startOfMonth, add } from "date-fns";
import { nl } from "date-fns/locale";

export function format(date, format) {
  return dateFnsFormat(date, format, { locale: nl });
}
export function getYearOfDate(date) {
  return getYear(date);
}

export const getMonths = () => {
  let months = [];
  for (let i = 0; i < 24; i++) {
    months.push(startOfMonth(add(new Date(), { months: i })));
  }
  return months;
}

export const getDuration = (from, to) => {
  const fromDate = new Date(from);
  const toDate = new Date(to);

  const timeDifference = toDate.getTime() - fromDate.getTime();
  return timeDifference / (1000 * 3600 * 24);
}