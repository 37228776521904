import { isEmpty } from "lodash";
import { parsePhoneNumberFromString } from "libphonenumber-js";

const regexName = /^[a-z ,.'-]+$/i;
const regexEmail = /.*@.*/;

export function isValidName(name) {
  return name && name.length > 0 && regexName.test(name);
}

export function isValidEmail(email) {
  return email && email.length > 0 && regexEmail.test(email);
}

export function isValidPhone(phone) {
  if (isEmpty(phone)) {
    return false;
  }

  const parsedPhone = parsePhoneNumberFromString(phone, "BE");
  return parsedPhone?.isValid() ?? false;
}
