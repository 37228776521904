import React from "react";
import Links from "./links/links";
import Legal from "./legal";
import Partners from "../partners";

export default () => (
  <>
    <Links />
    <Legal />
    <Partners/>
  </>
);
