import QsmSailingArea from "./qsmSailingArea";
import React from "react";
import { find, orderBy } from "lodash";

class QsmSailingAreas extends React.Component {
  // Utils

  isInSelectedSailingAreas(sailingArea) {
    const { selectedSailingAreas } = this.props;

    if (find(selectedSailingAreas, { id: sailingArea.id })) {
      return true;
    }
    return false;
  }

  // Lifecycle

  render() {
    const { sailingAreas, onSailingAreaClick } = this.props;

    return (
      <div className="panel__options">
        {Boolean(sailingAreas) &&
          orderBy(sailingAreas, [s => s.name]).map((sailingArea, index) => (
            <QsmSailingArea
              sailingArea={sailingArea}
              selected={this.isInSelectedSailingAreas(sailingArea)}
              onSailingAreaClick={onSailingAreaClick}
              key={index}
            />
          ))}
      </div>
    );
  }
}

export default QsmSailingAreas;
