import React from "react";
import QsmCruiseType from "./qsmCruiseType";
import { find } from "lodash";

class QsmCruiseTypes extends React.Component {
  // Utils

  isInSelectedCruiseTypes(cruiseType) {
    const { selectedCruiseTypes } = this.props;

    if (find(selectedCruiseTypes, { itemId: cruiseType.itemId })) {
      return true;
    }
    return false;
  }

  // Lifecycle

  render() {
    const { cruiseTypes, onCruiseTypeClick } = this.props;

    // Fast escape when there aren't any cruise types
    if (!cruiseTypes?.length) {
      return <></>;
    }

    return (
      <div className="panel__options">
        {cruiseTypes.map((cruiseType, index) => (
          <QsmCruiseType
            cruiseType={cruiseType}
            selected={this.isInSelectedCruiseTypes(cruiseType)}
            onCruiseTypeClick={onCruiseTypeClick}
            key={index}
          />
        ))}
      </div>
    );
  }
}

export default QsmCruiseTypes;
