import { Link } from "gatsby";
import React from "react";

export default ({ navigationColumn }) => {
  return (
    <>
      <h5>
        <Link to={navigationColumn.content.general.path}>{navigationColumn.content.general.title}</Link>
      </h5>
      {navigationColumn.childItems.slice(0, -1).map((item, index) => {
        return (
          <Link to={`/${item.content.general.path}`} key={index}>
            {item.content.general.title}
          </Link>
        );
      })}
    </>
  );
};
