import { Footer } from "../components/footer";
import Header from "../components/header";
import { Helmet } from "react-helmet";
import React, { useState, useEffect, useContext } from "react";
import { StickyContainer } from "react-sticky";
import GlobalContext from "../contexts/global-provider";

const Layout = ({
  children,
  isTiny,
  hasHeader,
  headerImage,
  headerText,
  showQsm,
  onQsmSearchClick,
  isTinted,
  cruiseTypes,
  erpSailingAreas,
}) => {
  const { theme } = useContext(GlobalContext);
  let className = theme;
  if (isTinted) {
    className += ` ${theme}--tinted`;
  }

  const [faviconApplePNG, setFaviconApplePNG] = useState(undefined);
  const [favicon16PNG, setFavicon16PNG] = useState(undefined);
  const [favicon32PNG, setFavicon32PNG] = useState(undefined);

  useEffect(() => {
    async function fetchImage() {
      setFaviconApplePNG(await import(`../../static/${theme}/favicon/apple-touch-icon.png`));
      setFavicon16PNG(await import(`../../static/${theme}/favicon/favicon-16x16.png`));
      setFavicon32PNG(await import(`../../static/${theme}/favicon/favicon-32x32.png`));
    }
    fetchImage();
  }, [theme]);

  // Output

  return (
    <StickyContainer className={className}>
      <Helmet>
        <link rel="apple-touch-icon" sizes="180x180" href={faviconApplePNG?.default} />
        <link rel="icon" type="image/png" sizes="32x32" href={favicon32PNG?.default} />
        <link rel="icon" type="image/png" sizes="16x16" href={favicon16PNG?.default} />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
        <link rel="stylesheet" href="https://use.typekit.net/jqv4gjo.css" />
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.min.css" />
      </Helmet>
      <Header
        isTiny={isTiny}
        hasHeader={hasHeader}
        headerImage={headerImage}
        headerText={headerText}
        showQsm={showQsm}
        onQsmSearchClick={onQsmSearchClick}
        cruiseTypes={cruiseTypes}
        sailingAreas={erpSailingAreas}
      />
      {children}
      <Footer/>
    </StickyContainer>
  );
};

export default Layout;
