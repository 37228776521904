import { Link } from "gatsby";
import React, { useContext } from "react";
import GlobalContext from "../contexts/global-provider";

const PrivacyPolicy = () => {
  const {privacy} = useContext(GlobalContext);
  return (
    <label className="form__privacypolicy">
      Door in te schrijven op de nieuwsbrief, gaat u akkoord met onze{" "}
      <Link to={"/" + (privacy ?? "")}>privacy policy</Link>.
    </label>
  );
};

export default PrivacyPolicy;
