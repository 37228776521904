import { add, endOfMonth, getYear, isAfter, isEqual, isPast, isThisMonth, startOfYear } from "date-fns";
import { clone, find } from "lodash";

import QsmCalendarMonth from "./qsmCalendarMonth";
import React from "react";
import { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useState } from "react";

const QsmCalendar = ({ selectedDates, onDateClick }) => {
  const [dates, setDates] = useState([]);
  const [filteredDates, setFilteredDates] = useState([]);

  const isMobile = useMediaQuery({ minWidth: 0, maxWidth: 576 });
  const isTablet = useMediaQuery({ minWidth: 576, maxWidth: 768 });

  // Actions

  const handleYearButtonClick = next => {
    let temporaryFilteredDates = clone(filteredDates);

    const numberOfCols = isMobile ? 1 : isTablet ? 2 : 3;
    if (next && temporaryFilteredDates.length > numberOfCols) {
      // Shift one year in future
      temporaryFilteredDates.shift();
    } else if (!next && temporaryFilteredDates.length < dates.length) {
      // Unshift one year
      const yearIndex = dates.length - temporaryFilteredDates.length - 1;
      const yearToAdd = dates[yearIndex];
      temporaryFilteredDates.unshift(yearToAdd);
    }

    setFilteredDates(temporaryFilteredDates);
  };

  // Lifecycle

  useEffect(() => {
    const dates = fetchDates();
    setDates(dates);
    setFilteredDates(dates);
  }, [selectedDates]);

  return (
    <div className="calendar">
      <div className="calendar__controls">
        <button type="button" className="button button--previous" onClick={() => handleYearButtonClick(false)} />
        <button type="button" className="button button--next" onClick={() => handleYearButtonClick(true)} />
      </div>
      <div className="calendar__groups">
        {filteredDates.map((year, index) => {
          return (
            <div className="calendar__group" key={index}>
              <label className="calendar__title">{year.year}</label>
              <div className="calendar__options">
                {year.months.map((month, indexMonth) => (
                  <QsmCalendarMonth
                    month={month}
                    selected={isInSelectedDates(month, selectedDates)}
                    disabled={Boolean(isInPast(month) || isInFarFuture(month))}
                    onDateClick={onDateClick}
                    key={indexMonth}
                  />
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default QsmCalendar;

// Data

const fetchDates = () => {
  const startOfCurrentYear = startOfYear(new Date());

  // Get next two years
  const years = [startOfCurrentYear, add(startOfCurrentYear, { years: 1 }), add(startOfCurrentYear, { years: 2 })];

  // Get months within each year
  const dates = years.map(year => {
    let monthsOfYear = [year];
    for (let i = 1; i < 12; i++) {
      monthsOfYear.push(add(year, { months: i }));
    }

    return {
      year: getYear(year),
      months: monthsOfYear,
    };
  });

  return dates;
};

// Utils

const isInSelectedDates = (month, selectedDates) => {
  if (find(selectedDates, selectedDate => isEqual(selectedDate, month))) {
    return true;
  }
  return false;
};

const isInPast = month => {
  return isPast(month) && !isThisMonth(month);
};

const isInFarFuture = month => {
  const monthToCompare = endOfMonth(add(new Date(), { months: 23 }));
  return isAfter(month, monthToCompare);
};
