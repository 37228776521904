import React, { useEffect, useState } from "react";

export default ({ headerText }) => {
  const [client, setClient] = useState(false);

  // Workaround to use the right className
  useEffect(() => {
    setClient(true);
  }, []);

  const key = client ? "client" : "server";

  return (
    <div className="header__title" key={key}>
      <h1>{headerText}</h1>
    </div>
  );
};
