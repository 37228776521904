const {
  environment: { host },
} = require("./environments/environment");

module.exports = {
  websiteName: {
    thalassa: "Thalassa",
    "select-together": "Select Together",
  },
  apiKey: "00eb6f5f-8dd6-4113-859b-97e5df155d28",
  apiUrl: `${host}/api/web/content`,
  host: host,
  catalogueId: 1,
  googleApiKey: "AIzaSyD9FhsgpnYloyOo0jLPfHmMtlu2W8ZY114",
};
