import GlobalContext from "../contexts/global-provider";
import Breadcrumbs from "./breadcrumbs";
import HeaderTitle from "./headerTitle";
import Navigation from "./navigation";
import Qsm from "./qsm/qsm";
import React, { useContext } from "react";

export default ({
  isTiny,
  hasHeader,
  headerImage,
  headerText,
  showQsm,
  onQsmSearchClick,
  cruiseTypes,
  sailingAreas,
}) => {
  const { theme } = useContext(GlobalContext);
  const className = isTiny ? "header header--tiny" : "header";
  if (!headerImage) {
    headerImage = {
      url: `/${theme}/jpg/header.jpg`,
    };
  }
  headerImage = !isTiny ? headerImage : { url: "" };
  return (
    <header className={className} style={{ backgroundImage: `url('${headerImage.url}')` }}>
      <Navigation isTiny={isTiny} />
      <Breadcrumbs hasHeader={hasHeader} />
      <HeaderTitle headerText={headerText} />
      {showQsm && (
        <Qsm isTiny={isTiny} onSearchClick={onQsmSearchClick} cruiseTypes={cruiseTypes} sailingAreas={sailingAreas} />
      )}
    </header>
  );
};
