export default [
  {
    id: 0,
    minDuration: 1,
    maxDuration: 8,
  },
  {
    id: 1,
    minDuration: 9,
    maxDuration: 16,
  },
  {
    id: 2,
    minDuration: 17,
    maxDuration: -1,
  },
];
