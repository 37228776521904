import React from "react";

class QsmCruiseType extends React.Component {
  constructor(props) {
    super(props);

    this.handleCruiseTypeClick = this.handleCruiseTypeClick.bind(this);
  }

  // Actions

  handleCruiseTypeClick(e) {
    e.preventDefault();

    const { cruiseType, onCruiseTypeClick } = this.props;

    if (onCruiseTypeClick) {
      onCruiseTypeClick(cruiseType);
    }
  }

  // Utils

  // Lifecycle

  render() {
    const { cruiseType, selected } = this.props;

    const baseClassName = "panel__option";
    const selectedClassName = "panel__option--selected";
    const className = `${baseClassName}${selected ? " " + selectedClassName : ""}`;

    return (
      <div className={className} onClick={this.handleCruiseTypeClick}>
        <p>{cruiseType.name}</p>
      </div>
    );
  }
}

export default QsmCruiseType;
