import React, { useState, useEffect, useContext } from "react";
import PrivacyPolicy from "./privacyPolicy";
import SubmitButton from "./submitButton";
import { isValidEmail, isValidName } from "../utils/validationUtils";
import { apiKey, host } from "../settings";
import { environment } from "../environments/environment";

const Newsletter = ({ description, templateId, showInFooter }) => {
  const initialSelection = {
    salutation: "",
    firstname: "",
    lastname: "",
    email: "",
  };

  // State

  const [isEnabled, setIsEnabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [selection, setSelection] = useState(initialSelection);
  const [touched, setTouched] = useState({});
  const [errors, setErrors] = useState({});

  // Actions

  const handleSubmit = e => {
    e.preventDefault();

    // Set all input fields to touched, so errors will become clear
    setTouched(
      Object.keys(selection).reduce((prev, cur) => {
        prev[cur] = true;
        return prev;
      }, {})
    );

    // Check if the form is valid
    const isValid = Object.keys(errors).length === 0;
    if (isValid) {
      sendNewsletterRequest();
    }
  };

  const handleChange = e => {
    setSelection({
      ...selection,
      [e.target.name]: e.target.value,
    });

    // Validation of selection
    validate({
      ...selection,
      [e.target.name]: e.target.value,
    });
  };

  const handleBlur = e => {
    setTouched({
      ...touched,
      [e.target.name]: true,
    });
  };

  // Data
  const sendConfirmationMail = async request => {
    const url = `${host}/api/web/mail`;
    const emailToSend = {
      firstName: request.firstName,
      lastName: request.lastName,
      emailAddress: request.email,
      emailTemplate: templateId,
    };
    setIsLoading(false);
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": apiKey,
      },
      body: JSON.stringify(emailToSend),
    });
  };

  const sendNewsletterRequest = () => {
    const request = {
      firstName: selection.firstname,
      lastName: selection.lastname,
      gender: parseGender(),
      email: selection.email,
      tags: [],
    };

    if (environment.builds[0].key === "thalassa") {
      request.tags.push(3);
    } else if (environment.builds[0].key === "select-together") {
      request.tags.push(4);
    }

    return (async () => {
      setIsEnabled(false);
      setIsLoading(true);

      const url = `${host}/api/web/crmcontact`;

      try {
        await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Api-Key": apiKey,
          },
          body: JSON.stringify(request),
        }).then(res => {
          if (res.ok) {
            setIsSent(true);
            setIsLoading(false);
            sendConfirmationMail(request);
          }
        });
      } catch (error) {
        console.error(error);
      }
    })();
  };

  const parseGender = () => {
    switch (selection.salutation) {
      case "dhr":
        return 0;
      case "mw":
        return 1;
      case "anders":
        return 2;
      default:
        return 0;
    }
  };

  // Validation

  const validate = selectionToValidate => {
    const errors = {};

    if (selectionToValidate.salutation.length === 0 && !showInFooter) {
      errors.salutation = {
        message: "Aanspreking *",
      };
    }

    if (!isValidName(selectionToValidate.firstname)) {
      errors.firstname = {
        message: "Voornaam *",
      };
    }

    if (!isValidName(selectionToValidate.lastname)) {
      errors.lastname = {
        message: "Naam *",
      };
    }

    if (!isValidEmail(selectionToValidate.email)) {
      errors.email = {
        message: "E-mail *",
      };
    }

    setErrors(errors);
  };

  // Lifecycle

  useEffect(() => {
    validate(selection);
  }, [selection]);

  return (
    <>
      <div className="newsletter__description" dangerouslySetInnerHTML={{ __html: description }}></div>
      <div className="newsletter__input">
        <div className="form-wrapper">
          <form className="form">
            {!showInFooter && (
              <div className="form__row form__row--salutation">
                <div className="form__col">
                  <label
                    className={`form__label ${touched.salutation && errors.salutation ? "form__label--error" : ""}`}
                  >
                    Aanspreektitel *
                  </label>
                  <div className="radio-buttons">
                    <div className="radio-button">
                      <input
                        id="sir"
                        type="radio"
                        name="salutation"
                        value="dhr"
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      <label htmlFor="sir">Dhr</label>
                    </div>

                    <div className="radio-button">
                      <input
                        id="mrs"
                        type="radio"
                        name="salutation"
                        value="mw"
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      <label htmlFor="mrs">Mw</label>
                    </div>

                    <div className="radio-button">
                      <input
                        id="different"
                        type="radio"
                        name="salutation"
                        value="anders"
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      <label htmlFor="different">Anders</label>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="form__row">
              <div className="form__col form__col--sm-50 form__col--md-33">
                <label
                  htmlFor="firstname"
                  className={`form__label ${touched.firstname && errors.firstname ? "form__label--error" : ""}`}
                >
                  Voornaam *
                </label>
                <input
                  className={`form__input ${touched.firstname && errors.firstname ? "form__input--error" : ""}`}
                  id="firstname"
                  name="firstname"
                  type="text"
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </div>
              <div className="form__col form__col--sm-50 form__col--md-33">
                <label
                  htmlFor="lastname"
                  className={`form__label ${touched.lastname && errors.lastname ? "form__label--error" : ""}`}
                >
                  Naam *
                </label>
                <input
                  className={`form__input ${touched.lastname && errors.lastname ? "form__input--error" : ""}`}
                  id="lastname"
                  name="lastname"
                  type="text"
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </div>
              <div className="form__col form__col--md-33">
                <label
                  htmlFor="email"
                  className={`form__label ${touched.email && errors.email ? "form__label--error" : ""}`}
                >
                  E-mail *
                </label>
                <input
                  className={`form__input ${touched.email && errors.email ? "form__input--error" : ""}`}
                  id="email"
                  name="email"
                  type="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </div>
            </div>
            {Boolean(Object.keys(touched).filter(key => Boolean(errors[key])).length) && (
              <div className="form__row form__row--alter">
                <div className="form__col">
                  <div className="error">Volgende velden zijn niet correct ingevuld. Kijk deze even na:</div>
                </div>
                <div className="form__col form__col--errors">
                  {Object.keys(touched)
                    .filter(key => Boolean(errors[key]))
                    .map((item, index) => (
                      <label className="errorlabel" key={index}>
                        {errors[item].message}
                      </label>
                    ))}
                </div>
              </div>
            )}
            {Boolean(isSent) && (
              <div className="form__row form__row--alternating">
                <div className="form__col">
                  <label className="successlabel">U bent ingeschreven op de nieuwsbrief!</label>
                </div>
              </div>
            )}
            <div className="form__row">
              <div className="form__col">
                <PrivacyPolicy />
              </div>
            </div>
            <div className="form__row form__row--submit">
              <div className="form__col">
                <SubmitButton text="Inschrijven" isLoading={isLoading} isEnabled={isEnabled} onClick={handleSubmit} isSecondary={showInFooter}/>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Newsletter;
