import { Link } from "gatsby";
import LinksColumn from "./linksColumn";
import LinksColumnFooter from "./linksColumnFooter";
import React, { useContext } from "react";
import GlobalContext from "../../../../contexts/global-provider";
import Partners from "../../partners";
import Newsletter from "../../../newsletter";

export default () => {
  const { navigation } = useContext(GlobalContext);
  const footerNavigation = navigation ? navigation.footerNavigation : {};
  const footerNavigation1 =
    footerNavigation && footerNavigation.childItems ? footerNavigation.childItems[0] : undefined;


  return (
    <section className="links">
      <div className="links__content">
        <div className="links__col links__col--8">
          <h4>
            {Boolean(footerNavigation1 && footerNavigation1.content) && (
              <Link to={`/${footerNavigation1.content.general.path}`}>{footerNavigation1.content.general.title}</Link>
            )}
          </h4>

          <div className="links__row">
            {Boolean(footerNavigation1 && footerNavigation1.childItems) &&
              footerNavigation1.childItems.map((item, index) => {
                return (
                  <div className="links__col links__col--4" key={index}>
                    <LinksColumn navigationColumn={item} />
                    <LinksColumnFooter navigationColumn={item} />
                  </div>
                );
              })}
          </div>
          <h4>SelectTogether Cruises</h4>
          <div className="links__row">
            <div className="links__col links__col--contactdata">
              <div className="links__contact">
                <div className="links__contact-group">
                  <a
                    href="https://www.google.be/maps/place/Vindictivelaan+6,+8400+Oostende/@51.2275958,2.9168567,17z/data=!3m1!4b1!4m5!3m4!1s0x47dca92aa59effcd:0x3f44ede4df0737b1!8m2!3d51.2275925!4d2.9190454"
                    title="Navigeer op Google Maps"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Vindictivelaan 6<br />
                    8400 Oostende
                  </a>
                </div>
                <div className="links__contact-group">
                  <a
                    href="tel:059708770"
                    title="059 70 87 70"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link link--plain"
                  >
                    059 70 87 70
                  </a>
                  <a
                    href="mailto:cruise@thalassa.be"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link link--plain"
                  >
                    info@selectcruises.be
                  </a>
                </div>
              </div>

              <div className="links__openingtimes">
                <div className="links__openingtimes-group">
                  <div className="links__openingtimes-label">
                    <strong>MA-VR</strong>
                  </div>
                  <div className="links__openingtimes-hours">
                    09:30 - 12:30
                    <br />
                    13:30 - 18:00
                  </div>
                </div>

                <div className="links__openingtimes-group">
                  <div className="links__openingtimes-label">
                    <strong>ZA - ZO</strong>
                  </div>
                  <div className="links__openingtimes-hours">Gesloten</div>
                </div>
              </div>

              <div className="links__approval-number">
                <strong>Erkenningsnummer: 1885</strong> | BE 0427.852.746
              </div>
            </div>
          </div>
        </div>
        <div className="links__col links__col--4">
          <h4>Mis de boot niet.</h4>

          <div className="links__newsletter">
            <Newsletter
              description={navigation?.newsletterText}
              templateId={navigation?.newsletterTemplate?.tideId}
              showInFooter={true}
            ></Newsletter>
          </div>

          <h4>Volg ons</h4>
          <div className="links__social">
            <div className="links__social-item">
              <a href="https://www.facebook.com/SelectTogetherCruises/" target="_blank">
                <i className="fab fa-fw fa-facebook-f"></i> Facebook
              </a>
            </div>
            <div className="links__social-item">
              <a href="https://www.youtube.com/channel/UC02NB9taG_RAinBYr9_y58w" target="_blank">
                <i className="fab fa-fw fa-youtube"></i> YouTube
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="links__contact-partners">
        <div className="links__content-wrapper">
          <div className="contactdata">
            <h4>SelectTogether Cruises</h4>
            <div className="links__row">
              <div className="links__col links__col--6">
                <a
                  href="https://www.google.be/maps/place/Vindictivelaan+6,+8400+Oostende/@51.2275958,2.9168567,17z/data=!3m1!4b1!4m5!3m4!1s0x47dca92aa59effcd:0x3f44ede4df0737b1!8m2!3d51.2275925!4d2.9190454"
                  title="Navigeer op Google Maps"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Vindictivelaan 6<br />
                  8400 Oostende
                </a>
              </div>
              <div className="links__col links__col--6">
                <a href="tel:059708770">059 70 87 70</a>
                <a href="mailto:cruise@thalassa.be">info@selectcruises.be</a>
              </div>
            </div>

            <div className="links__row">
              <span>
                <strong>Erkenningsnummer:</strong> 1885 | BE 0427.852.746
              </span>
            </div>
            <div className="links__row">
              <div className="links__col links__col--6 links__hours">
                <div>
                  <strong>MA - VR</strong>
                </div>
                <div>
                  09:30 - 12:30
                  <br />
                  13:30 - 18:00
                </div>
              </div>
              <div className="links__col links__col--6 links__hours">
                <div>
                  <strong>ZA - ZO</strong>
                </div>
                <div>Gesloten</div>
              </div>
            </div>
          </div>
          <Partners />
        </div>
      </div> */}
    </section>
  );
};
