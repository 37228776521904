import React, { useContext } from "react";
import SelectTogetherFooter from "./select-together/footer";
import ThalassaFooter from "./thalassa/footer";
import GlobalContext from "../../contexts/global-provider";

const Footer = () => {
  const { theme } = useContext(GlobalContext);
  return <footer className="footer">{theme === "thalassa" ? <ThalassaFooter /> : <SelectTogetherFooter />}</footer>;
};

export default Footer;
