import React, { useContext, useState } from "react";

import { Link } from "gatsby";
import { Sticky } from "react-sticky";
import { getChildItemsWithTypename } from "../utils/cmsUtils";
import GlobalContext from "../contexts/global-provider";

export default ({ isTiny }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const {navigation, theme} = useContext(GlobalContext);
  const headerNavigation = navigation ? navigation.headerNavigation : {};
  const contactNavigation = navigation ? navigation.contactNavigation : {};  

  let headerNavigationChilds = [];
  if (headerNavigation) {
    headerNavigationChilds = getChildItemsWithTypename("TideItemForNavigatieItem", headerNavigation);
  }
  let contactNavigationChilds = [];
  if (contactNavigation) {
    contactNavigationChilds = getChildItemsWithTypename("TideItemForNavigatieItem", contactNavigation);
  }
  // Actions

  const handleHamburgerClick = () => {
    setMenuOpen(!isMenuOpen);
  };

  // Utils

  const getPrimaryNavigationItems = items => {
    if (!items?.length) {
      return [];
    }
    return items.slice(0, 3);
  };

  const getSecondaryNavigationItems = items => {
    if (!items?.length) {
      return [];
    }
    return items.slice(3, items.length);
  };

  const ensureLeadingSlash = path => {
    if (typeof path === "string" && !path.startsWith("/")) {
      return `/${path}`;
    }
    return path;
  };

  // Render

  return (
    <Sticky>
      {({ style, isSticky, wasSticky, distanceFromTop }) => {
        // Sticky navigation
        let className = (isSticky && distanceFromTop && distanceFromTop < 0) || isTiny ? "nav nav--sticky" : "nav";

        // Responsive navigation
        className = isMenuOpen ? "nav nav--active" : className;

        return (
          <nav className={className} style={style}>
            <button type="button" className="button button--hamburger" onClick={handleHamburgerClick}>
              <span className="button__icon"></span>
            </button>

            <div className="nav__logo">
              <Link to="/">
                <img src={`/${theme}/svg/logo.svg`} alt="Logo" className="nav__logo-media"></img>
              </Link>
            </div>

            <div className="nav__lists">
              <ul className="nav__list nav__list--primary">
                {getPrimaryNavigationItems(headerNavigationChilds).map((navigationItem, index) => (
                  <li className="nav__listitem" key={index}>
                    <Link
                      to={ensureLeadingSlash(navigationItem.content.general.path) ?? "/"}
                      title={navigationItem.content.general.title ?? ""}
                      className="link link--plain"
                    >
                      {navigationItem.content.general.title ?? ""}
                    </Link>
                  </li>
                ))}
              </ul>

              <ul className="nav__list nav__list--secondary">
                {getSecondaryNavigationItems(headerNavigationChilds).map((navigationItem, index) => (
                  <li className="nav__listitem" key={index}>
                    <Link
                      to={ensureLeadingSlash(navigationItem.content.general.path) ?? "/"}
                      title={navigationItem.content.general.title ?? ""}
                      className="link link--plain"
                    >
                      {navigationItem.content.general.title ?? ""}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            {Boolean(contactNavigationChilds?.length) && (
              <div className="nav__contact">
                <ul>
                  {contactNavigationChilds.map((contactNavigationItem, index) => (
                    <li key={index}>
                      <a
                        href={contactNavigationItem.content.general.path}
                        title={contactNavigationItem.content.general.title ?? ""}
                        className="link link--plain"
                      >
                        <i className="link__icon fa fa-phone-alt" />
                        <span className="link__text">{contactNavigationItem.content.general.title ?? ""}</span>
                      </a>
                    </li>
                  ))}
                </ul>{" "}
              </div>
            )}
          </nav>
        );
      }}
    </Sticky>
  );
};
