import { Link } from "gatsby";
import React from "react";

export default ({ navigationColumn }) => {
  return (
    <>
      {Boolean(navigationColumn && navigationColumn.childItems) &&
        navigationColumn.childItems.slice(-1).map((item, index) => {
          return (
            <Link className="a--underline a--viewall" to={`/${item.content.general.path}`} key={index}>
              {item.content.general.title}
            </Link>
          );
        })}
    </>
  );
};
