import { add, fromUnixTime, getUnixTime, isEqual, isWithinInterval, max, min, startOfMonth } from "date-fns";
import { clone, find, isArray, maxBy, minBy, remove } from "lodash";

import Button from "../button";
import QsmCalendar from "./qsmCalendar";
import QsmCruiseTypes from "./qsmCruiseTypes";
import QsmDurations from "./qsmDurations";
import QsmSailingAreas from "./qsmSailingAreas";
import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import durations from "../../data/qsm/durations";
import { format } from "../../utils/dateUtils";
import { navigate } from "gatsby";
import GlobalContext from "../../contexts/global-provider";
import { setSearchQuery } from "../../state/appActions";

const Qsm = ({ isTiny, sailingAreas, cruiseTypes, searchQuery, setSearchQuery }) => {
  const { searchSelection, setSearchSelection } = useContext(GlobalContext);

  const dateFormat = "MMM yyyy";
  const productsPathname = "/products/";

  const [showCruiseTypesPopup, setShowCruiseTypesPopup] = useState(false);
  const [showSailingAreasPopup, setShowSailingAreasPopup] = useState(false);
  const [showDatesPopup, setShowDatesPopup] = useState(false);
  const [showDurationsPopup, setShowDurationsPopup] = useState(false);
  const [localSearch, setLocalSearch] = useState({...searchSelection});

  // Actions

  const handleCruiseTypesClick = e => {
    e.preventDefault();

    setShowCruiseTypesPopup(!showCruiseTypesPopup);
    setShowSailingAreasPopup(false);
    setShowDatesPopup(false);
    setShowDurationsPopup(false);
  };

  const handleCruiseTypeClick = e => {
    let selectedCruiseTypes = clone(localSearch.selectedCruiseTypes);

    if (!find(selectedCruiseTypes, { itemId: e.itemId })) {
      // Cruise type isn't in selection yet, add it
      selectedCruiseTypes.push(e);
    } else {
      // Cruise type is in selection, remove it
      remove(selectedCruiseTypes, { itemId: e.itemId });
    }

    setLocalSearch({ ...localSearch, selectedCruiseTypes });
  };

  const handleSailingAreasClick = e => {
    e.preventDefault();

    setShowCruiseTypesPopup(false);
    setShowSailingAreasPopup(!showSailingAreasPopup);
    setShowDatesPopup(false);
    setShowDurationsPopup(false);
  };

  const handleSailingAreaClick = e => {
    let selectedSailingAreas = clone(localSearch.selectedSailingAreas);

    if (!find(selectedSailingAreas, { id: e.id })) {
      // Sailing area isn't in selection yet, add it
      selectedSailingAreas.push(e);
    } else {
      // Sailing area is in selection, remove it
      remove(selectedSailingAreas, { id: e.id });
    }

    setLocalSearch({ ...localSearch, selectedSailingAreas });
  };

  const handleDatesClick = e => {
    e.preventDefault();

    setShowCruiseTypesPopup(false);
    setShowSailingAreasPopup(false);
    setShowDatesPopup(!showDatesPopup);
    setShowDurationsPopup(false);
  };

  const handleDateClick = e => {
    let selectedDates = clone(localSearch.selectedDates);

    let removed = false;
    if (!find(selectedDates, selectedDate => isEqual(selectedDate, e))) {
      // Date isn't in selection yet, add it
      selectedDates.push(e);
    } else {
      // Date is in selection, remove it
      remove(selectedDates, selectedDate => isEqual(selectedDate, e));
      removed = true;
    }

    if (selectedDates.length === 0) {
      // No items in array. No need to fill the gaps
      setLocalSearch({ ...localSearch, selectedDates });

      return;
    }

    // Calculate range
    const minDate = min(selectedDates);
    let maxDate = max(selectedDates);

    if (removed && isWithinInterval(e, { start: minDate, end: maxDate })) {
      maxDate = add(e, { months: -1 });
    }

    // Fill the gaps
    let processedSelectedDates = [];
    let date = minDate;

    while (!isEqual(date, maxDate)) {
      processedSelectedDates.push(date);
      date = add(date, { months: 1 });
    }
    processedSelectedDates.push(date);

    setLocalSearch({ ...localSearch, selectedDates: processedSelectedDates });
  };

  const handleDurationsClick = e => {
    e.preventDefault();

    setShowCruiseTypesPopup(false);
    setShowSailingAreasPopup(false);
    setShowDatesPopup(false);
    setShowDurationsPopup(!showDurationsPopup);
  };

  const handleDurationClick = e => {
    let selectedDurations = clone(localSearch.selectedDurations);

    if (!find(selectedDurations, { id: e.id })) {
      // Duration isn"t in selection yet, add it
      selectedDurations.push(e);
    } else {
      // Duration is in selection, remove it
      remove(selectedDurations, { id: e.id });
    }

    setLocalSearch({ ...localSearch, selectedDurations });
  };

  const handleCloseClick = e => {
    setShowCruiseTypesPopup(false);
    setShowSailingAreasPopup(false);
    setShowDatesPopup(false);
    setShowDurationsPopup(false);
  };

  const handleSearchClick = e => {
    e.preventDefault();

    // Close qsm
    setShowCruiseTypesPopup(false);
    setShowSailingAreasPopup(false);
    setShowDatesPopup(false);
    setShowDurationsPopup(false);

    const selection = [];

    // CruiseTypes
    const queryCruiseTypes = createQueryString(
      "cruiseType",
      localSearch.selectedCruiseTypes.map(cruiseType => cruiseType.itemId)
    );
    if (queryCruiseTypes) {
      selection.push(queryCruiseTypes);
    }

    // SailingAreas
    const querySailingAreas = createQueryString(
      "sailingArea",
      localSearch.selectedSailingAreas.map(selectedSailingArea => selectedSailingArea.id)
    );
    if (querySailingAreas) {
      selection.push(querySailingAreas);
    }

    // Dates
    let selectedMinDate = min(localSearch.selectedDates);
    let selectedMaxDate = max(localSearch.selectedDates);
    if (!isNaN(selectedMinDate.getTime()) && !isNaN(selectedMaxDate.getTime())) {
      const minDate = `minDate=${getUnixTime(selectedMinDate)}`;
      selection.push(minDate);

      const maxDate = `maxDate=${getUnixTime(selectedMaxDate)}`;
      selection.push(maxDate);
    }

    // Durations
    const queryDurations = createQueryString(
      "duration",
      localSearch.selectedDurations.map(selectedDuration => selectedDuration.id)
    );
    if (queryDurations) {
      selection.push(queryDurations);
    }

    // ShippingCompany
    if (localSearch.shippingCompanyId !== null) {
      localSearch.shippingCompanyId = null;
      setLocalSearch(localSearch);
    }

    const queryString = `${selection.join("&")}`;
    navigate(`${productsPathname}${queryString ? `?${queryString}` : ""}`);

    setSearchSelection(localSearch);
  };

  // Utils

  const createQueryString = (propertyName, values) => {
    if (!isArray(values)) {
      return `${propertyName}=${values}`;
    }

    const parsedValues = values.map(value => {
      return `${propertyName}=${value}`;
    });
    return parsedValues.join("&");
  };

  const parseQueryString = () => {
    let selectedCruiseTypes;
    let selectedSailingAreas;
    let selectedDates;
    let selectedDurations;
    let shippingCompanyId;

    if (typeof window !== "undefined") {
      // Map selectedCruiseTypes to cruiseType objects
      selectedCruiseTypes = new URLSearchParams(window.location.search).getAll("cruiseType");
      selectedCruiseTypes = selectedCruiseTypes.map(selectedCruiseType =>
        find(cruiseTypes, { itemId: Number(selectedCruiseType) })
      );
      selectedCruiseTypes = Array.isArray(selectedCruiseTypes) && selectedCruiseTypes.length ? selectedCruiseTypes : [];

      // Map sailingAreas to sailingArea objects
      selectedSailingAreas = new URLSearchParams(window.location.search).getAll("sailingArea");
      selectedSailingAreas = selectedSailingAreas.map(selectedSailingArea =>
        find(sailingAreas, { id: Number(selectedSailingArea) })
      );
      selectedSailingAreas =
        Array.isArray(selectedSailingAreas) && selectedSailingAreas.length ? selectedSailingAreas : [];

      // Map min date
      let selectedMinDate = new URLSearchParams(window.location.search).get("minDate");
      selectedMinDate = selectedMinDate ? fromUnixTime(selectedMinDate) : selectedMinDate;
      selectedMinDate = selectedMinDate ? selectedMinDate : startOfMonth(new Date());

      // Map max date
      let selectedMaxDate = new URLSearchParams(window.location.search).get("maxDate");
      selectedMaxDate = selectedMaxDate ? fromUnixTime(selectedMaxDate) : selectedMaxDate;
      selectedMaxDate = selectedMaxDate ? selectedMaxDate : add(selectedMinDate, { months: 23 });

      // Map selected dates
      selectedDates = [];
      let date = selectedMinDate;

      while (!isEqual(date, selectedMaxDate)) {
        selectedDates.push(date);
        date = add(date, { months: 1 });
      }
      selectedDates.push(date);

      selectedDates = selectedDates.length ? selectedDates : [];

      // Map durations to duration objects
      selectedDurations = new URLSearchParams(window.location.search).getAll("duration");
      selectedDurations = selectedDurations.map(selectedDuration => find(durations, { id: Number(selectedDuration) }));
      selectedDurations = selectedDurations ? selectedDurations : [];

      // Map shippinCompany
      shippingCompanyId = new URLSearchParams(window.location.search).get("shippingCompany");
      shippingCompanyId = shippingCompanyId ? [Number(shippingCompanyId)] : undefined;
    }

    return {
      selectedCruiseTypes,
      selectedSailingAreas,
      selectedDates,
      selectedDurations,
      shippingCompanyId,
    };
  };

  const getCruiseTypeTitle = selectedCruiseTypes => {
    if (!Array.isArray(selectedCruiseTypes) || !selectedCruiseTypes.length) {
      return "Elk type cruise";
    }

    return selectedCruiseTypes.length > 1
      ? selectedCruiseTypes.map(selectedCruiseType => selectedCruiseType.name).join(", ")
      : selectedCruiseTypes[0].name;
  };

  const getSailingAreasTitle = selectedSailingAreas => {
    if (!Array.isArray(selectedSailingAreas) || !selectedSailingAreas.length) {
      return "Alle bestemmingen";
    }

    return selectedSailingAreas.length > 1
      ? selectedSailingAreas.map(selectedSailingArea => selectedSailingArea.name).join(", ")
      : selectedSailingAreas[0].name;
  };

  const getDatesTitle = selectedDates => {
    if (!Array.isArray(selectedDates) || !selectedDates.length) {
      return "Elke vertrekdatum";
    }

    const minDate = min(selectedDates);
    const maxDate = max(selectedDates);

    const start = format(minDate, dateFormat);
    const end = format(maxDate, dateFormat);

    return isEqual(minDate, maxDate) ? start : `${start} - ${end}`;
  };

  const getDurationTitle = selectedDurations => {
    if (!Array.isArray(selectedDurations) || !selectedDurations.length) {
      return "Elke reisduur";
    }

    const minDuration = minBy(selectedDurations, "minDuration");
    const maxDuration = maxBy(selectedDurations, "minDuration");
    var minText = minDuration.minDuration;
    var separator = maxDuration.maxDuration > 0 ? "-" : "of";
    var maxText = maxDuration.maxDuration > 0 ? maxDuration.maxDuration : "meer";

    return `${minText} ${separator} ${maxText} dagen`;
  };

  // Lifecycle

  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      window.location &&
      window.location.pathname === productsPathname &&
      window.location.search &&
      sailingAreas?.length &&
      cruiseTypes?.length
    ) {
      // New query is different to existing query
      const currentQuery = window.location.search;

      if (currentQuery !== searchQuery) {
        // Update selection
        const selection = parseQueryString(sailingAreas, cruiseTypes);
        // setSearchSelection(selection);
        setLocalSearch(selection);
        setSearchQuery(currentQuery);
      }
    }
  }, []);

  useEffect(() => {
    setLocalSearch(searchSelection);
  }, [searchSelection]);

  const isQsmOpen = showCruiseTypesPopup || showSailingAreasPopup || showDatesPopup || showDurationsPopup;
  return (
    <div className={isTiny ? "qsm qsm--tiny" : "qsm"}>
      <div className={isQsmOpen ? "qsm__row qsm__row--open" : "qsm__row qsm__row--closed"}>
        <div className="qsm__backdrop" onClick={handleCloseClick} />

        <div className="qsm__col" onClick={handleCruiseTypesClick}>
          <label className="label label--qsm">Cruisetype</label>
          <div
            className={
              showCruiseTypesPopup
                ? "qsm__input qsm__input--value qsm__input--selected"
                : "qsm__input qsm__input--value"
            }
          >
            <p className="qsm__input-value">{getCruiseTypeTitle(localSearch.selectedCruiseTypes)}</p>
          </div>
        </div>

        <div className="qsm__col" onClick={handleSailingAreasClick}>
          <label className="label label--qsm">Bestemming</label>
          <div
            className={
              showSailingAreasPopup
                ? "qsm__input qsm__input--value qsm__input--selected"
                : "qsm__input qsm__input--value"
            }
          >
            <p className="qsm__input-value">{getSailingAreasTitle(localSearch.selectedSailingAreas)}</p>
          </div>
        </div>

        <div className="qsm__col" onClick={handleDatesClick}>
          <label className="label label--qsm">Vertrekdatum</label>
          <div
            className={
              showDatesPopup ? "qsm__input qsm__input--value qsm__input--selected" : "qsm__input qsm__input--value"
            }
          >
            <p className="qsm__input-value">{getDatesTitle(localSearch.selectedDates)}</p>
          </div>
        </div>

        <div className="qsm__col" onClick={handleDurationsClick}>
          <label className="label label--qsm">Reisduur</label>
          <div
            className={
              showDurationsPopup ? "qsm__input qsm__input--value qsm__input--selected" : "qsm__input qsm__input--value"
            }
          >
            <p className="qsm__input-value">{getDurationTitle(localSearch.selectedDurations)}</p>
          </div>
        </div>

        <div className="qsm__col qsm__col--submit">
          <div className="qsm__input qsm__input--button" onClick={handleSearchClick}>
            <Button text="Zoeken" extraClass="button--primary" />
          </div>
        </div>

        {Boolean(isQsmOpen) && (
          <div className="panel panel--qsm">
            <div className="panel__actions" onClick={handleCloseClick}>
              <button className="button button--close">
                <span className="button__copy">Sluiten</span>
              </button>
            </div>
            {Boolean(showCruiseTypesPopup) && (
              <QsmCruiseTypes
                cruiseTypes={cruiseTypes}
                selectedCruiseTypes={localSearch.selectedCruiseTypes}
                onCruiseTypeClick={handleCruiseTypeClick}
              />
            )}
            {Boolean(showSailingAreasPopup) && (
              <QsmSailingAreas
                sailingAreas={sailingAreas}
                selectedSailingAreas={localSearch.selectedSailingAreas}
                onSailingAreaClick={handleSailingAreaClick}
              />
            )}
            {Boolean(showDatesPopup) && (
              <QsmCalendar selectedDates={localSearch.selectedDates} onDateClick={handleDateClick} />
            )}
            {Boolean(showDurationsPopup) && (
              <QsmDurations
                durations={durations}
                selectedDurations={localSearch.selectedDurations}
                onDurationClick={handleDurationClick}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

// Redux

function mapStateToProps(state) {
  const {
    app: { searchQuery },
  } = state;

  return {
    searchQuery,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setSearchQuery: searchQuery => dispatch(setSearchQuery(searchQuery)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Qsm);
